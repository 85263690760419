import { useEffect, useState } from 'react';
import useProvideAuth from './useProvideAuth';
import useFetchData from './useFetchData';

export default function useCashFlow(params) {
  const { isLogin } = useProvideAuth();
  const [cashFlow, setCashFlow] = useState(null);

  // Reuse the useFetchData hook
  const { data, error, mutate } = useFetchData(`portfolio/cashflow`, params);

  useEffect(() => {
    if (data && isLogin) {
      setCashFlow(data);
    }
    if (error) {
      setCashFlow(null);
    }
  }, [data, error, isLogin]);

  return { cashFlow, mutateCashFlow: mutate };
}