import { useState, useEffect } from 'react';
import useProvideAuth from '../useProvideAuth';
import useFetchData from './useFetchData';

export default function usePortfolioPerformance(params) {
  const { isLogin } = useProvideAuth();
  const [portfolioPerformance, setPortfolioPerformance] = useState(null);

  const { data, error, mutate } = useFetchData('portfolio-performance', params);

  useEffect(() => {
    if (data && isLogin) {
      setPortfolioPerformance(data);
    }
    if (error) {
      setPortfolioPerformance(null);
    }
  }, [data, error, isLogin]);

  return { portfolioPerformance, mutatePortfolioPerformance: mutate };
}