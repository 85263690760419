// AssetAllocation.tsx
import React from 'react'
import { Col, Row, Empty } from 'antd';
import { Text, Box } from '@chakra-ui/react';
import Highcharts from "highcharts/highstock";
import drilldow from "highcharts/modules/drilldown";
import PieChart from "highcharts-react-official";
import VariablePie from "highcharts/modules/variable-pie.js";
import { convertToPercentage, convertToMillions } from '../../../../utils/commonFunctions';
import './styles.scss'

drilldow(Highcharts);
VariablePie(Highcharts);

interface ComponentProps {
  assetAllocation: any | null
}

interface StockAllocation {
  name: string;
  weight: number;
}

interface AssetAllocation {
  pct_cash: number;
  pct_stock: number;
  total_stock: number;
  total_cash: number;
  stock_alloc: StockAllocation[];
}

const AssetAllocation: React.FC<ComponentProps> = ({ assetAllocation }) => {

  if (!(assetAllocation && (assetAllocation['pct_stock'] || assetAllocation['pct_cash']))) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  const pctCash = convertToPercentage(assetAllocation['pct_cash']);
  const pctStock = convertToPercentage(assetAllocation['pct_stock']);
  const totalValue = convertToMillions((assetAllocation['total_cash'] + assetAllocation['total_stock']).toString());

  const data = [];
  if (pctStock > 0) {
    data.push({ name: 'Cổ phiếu', y: pctStock });
  }
  if (pctCash > 0) {
    data.push({ name: 'Tiền mặt', y: pctCash });
  }

  const sectorAlloc = (assetAllocation['sector_alloc'] as StockAllocation[])?.map(item => ({
    ...item,
    y: convertToPercentage(item?.weight),
  }));


  // Create the chart
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [[0, '#EDF2F7']],
      },
    },
    colors: ['#1F4172', '#E7A86E'],
    title: {
      text: totalValue ?? 0,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        cursor: 'pointer',
        shadow: false,
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 4,
        center: ['50%', '50%'],
        size: '80%',
        startAngle: -90,
        endAngle: 90,
        dataLabels: {
          pointFormat:
            "<div class='pie-box' style='color: {point.color};'>" +
            "<b class='pie-label'>{point.name}</b><br/>" +
            "<span>{point.percentage:.1f}%</span>" + // Adding percentage label
            "</div>",
          style: {
            textOutline: 'none',
          },
          useHTML: true
        },
        point: {
          events: {},
        },
      },
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'Total',
        colorByPoint: true,
        innerSize: '50%',
        data: data
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}, { id: 'versions', dataLabels: { enabled: false } }],
          },
        },
      ],
    },
  };

  // Create the chart
  const sectorOptions = {
    chart: {
      type: 'pie',
      backgroundColor: {
        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
        stops: [[0, '#EDF2F7']],
      },
    },
    colors: ['#90B3E5', '#6F98D2', '#3A65A3', '#1F4172'],
    title: {
      text: 'Ngành',
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {

        cursor: 'pointer',
        shadow: false,
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 4,
        center: ['50%', '50%'],
        size: '80%',
        dataLabels: {
          pointFormat:
            "<div class='pie-box' style='color: {point.color};'>" +
            "<b>{point.name}</b><br/>" +
            "<span>{point.percentage:.1f}%</span>" + // Adding percentage label
            "</div>",
          style: {
            textOutline: 'none',
          },
          useHTML: true
        },
        point: {
          events: {},
        },
      },
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        name: 'Total',
        colorByPoint: true,
        innerSize: '50%',
        data: sectorAlloc ?? []
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}, { id: 'versions', dataLabels: { enabled: false } }],
          },
        },
      ],
    },
  };

  return (
    <Box>
      <Text align={'center'} fontSize={'2xl'}>TỶ TRỌNG PHÂN BỔ TÀI SẢN</Text>
      <Row justify="center">
        {
          data.length > 0 && (
            <Col span={12}>
              <Box p={3}>
                <PieChart highcharts={Highcharts} options={options} />
              </Box>
            </Col>
          )
        }
        {
          sectorAlloc.length > 0 && (
            <Col span={12}>
              <Box p={3}>
                <PieChart highcharts={Highcharts} options={sectorOptions} />
              </Box>
            </Col>
          )
        }
      </Row>
    </Box>
  );
};

export default AssetAllocation;
