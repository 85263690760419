/**
 * Convert a decimal to percentage with one decimal precision.
 * @param decimal - The decimal value to convert.
 * @returns The percentage value.
 */
export const convertToPercentage = (decimal: number): number => {
    const percentage = decimal * 100;
    return Math.round(percentage * 10) / 10;
};

/**
 * Convert a numeric string to millions format.
 * @param inputNumber - The input number as a string.
 * @returns The formatted string in millions.
 */
export const convertToMillions = (inputNumber: string): string => {
    const number = parseFloat(inputNumber);
    if (!isNaN(number)) {
        const millions = number / 1000000;
        return millions ? `${parseFloat(millions?.toFixed(1))} M` : 'N/A';
    } else {
        return 'N/A';
    }
};

/**
 * Convert a numeric string to billions format.
 * @param inputNumber - The input number as a string.
 * @returns The formatted string in millions.
 */
export const convertToBillions = (inputNumber: string) => {
    const number = parseFloat(inputNumber);
    if (!isNaN(number)) {
        const millions = number / 1000000000;
        return `${parseFloat(millions.toFixed(1))} B`;
    } else {
        return 'N/A';
    }
};

interface DataType {
    last_updated: string;
}
export const getLatestUpdated = (data: DataType[]): string => {
    // Convert last_updated strings to Date objects and find the most recent one
    const latestItem = data?.reduce((latest, item) => {
        const itemDate = new Date(item?.last_updated);
        return itemDate > new Date(latest?.last_updated) ? item : latest;
    }, data[0]);

    return latestItem?.last_updated;
};
