// LoginFeature.tsx
import React, { useState } from 'react';
import Layout from '../../layouts/Login';
import { FaUserAlt, FaLock } from "react-icons/fa";
import { chakra, Box, Container, Flex, Stack, Button, FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Heading, Avatar } from '@chakra-ui/react';
import { notification } from 'antd';
import { useProvideAuth } from '../../hooks';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const LoginFeature: React.FC = () => {

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type: NotificationType, title: string, description: string | string[] | { type: string, loc: (string | number)[], msg: string, input: object }[]) => {
        let formattedDescription: string;

        if (Array.isArray(description)) {
            if (typeof description[0] === 'string') {
                // Join the array elements into a single string, separated by newlines
                formattedDescription = (description as string[]).join('</br>');
            } else {
                // Format the array of error objects
                formattedDescription = (description as { type: string, loc: (string | number)[], msg: string, input: object }[])
                    .map(err => `${err.loc[2]}: ${err.msg}`)
                    .join('</br>');
            }
        } else {
            // If it's already a string, use it as is
            formattedDescription = description;
        }

        api[type]({
            message: title,
            description: <span>{formattedDescription}</span>,
        });
    };

    const provideAuth = useProvideAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleShowClick = () => setShowPassword(!showPassword);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const loginResponse = await loginUser(username, password);
            if (!loginResponse || !loginResponse.access_token) {
                openNotificationWithIcon('error', 'Error', `Login failed. No access token received.`);
                throw new Error("Login failed. No access token received.");
            } else {
                localStorage.setItem('jwtToken', loginResponse.access_token);
            }

            const tokenResponse = await exchangeToken(loginResponse.access_token);
            if (!tokenResponse || !tokenResponse.access_token) {
                openNotificationWithIcon('error', 'Error', `Token exchange failed.`);
                throw new Error("Token exchange failed.");
            }

            provideAuth.login(tokenResponse.access_token);
        } catch (error: any) {
            openNotificationWithIcon('error', 'Error', error.message);
            console.error("Error during login:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Separate function to handle login API call
    const loginUser = async (email: string, password: string) => {
        const response = await fetch(`${process.env.REACT_APP_AUTH}/api/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ email, password }),
            redirect: "follow"
        });

        if (!response.ok) {
            openNotificationWithIcon('error', 'Error', `Failed to login.`);
            throw new Error("Failed to login.");
        }

        return response.json();
    };

    // Separate function to handle token exchange
    const exchangeToken = async (accessToken: string) => {
        const headers = new Headers({
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json"
        });

        const params = new URLSearchParams({
            username: `${process.env.REACT_APP_USERNAME}`,
            password: `${process.env.REACT_APP_PASSWORD}`,
            access_token: accessToken
        });

        const response = await fetch(`${process.env.REACT_APP_BACKEND}/token`, {
            method: "POST",
            headers: headers,
            body: params,
            redirect: "follow"
        });

        if (!response.ok) {
            openNotificationWithIcon('error', 'Error', `Failed to exchange token.`);
            throw new Error("Failed to exchange token.");
        }

        return response.json();
    };

    return (
        <Layout>
            {contextHolder}
            <Container maxW={'container.6xl'}>
                <Flex
                    flexDirection="column"
                    width="100wh"
                    height="calc(100vh - 256px)"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Stack
                        flexDir="column"
                        mb="2"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Avatar bg="brand.500" />
                        <Heading color="brand.400">Welcome</Heading>
                        <Box minW={{ base: "90%", md: "468px" }}>
                            <form onSubmit={handleSubmit}>
                                <Stack
                                    spacing={4}
                                    p="1rem"
                                    backgroundColor="whiteAlpha.900"
                                    boxShadow="md"
                                >
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                children={<CFaUserAlt color="gray.300" />}
                                            />
                                            <Input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents="none"
                                                color="gray.300"
                                                children={<CFaLock color="gray.300" />}
                                            />
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                required
                                            />
                                            <InputRightElement width="4.5rem">
                                                <Button h="1.75rem" size="sm" onClick={handleShowClick} isLoading={isLoading}>
                                                    {showPassword ? "Hide" : "Show"}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <Button
                                        borderRadius={0}
                                        type="submit"
                                        variant="solid"
                                        colorScheme="brand"
                                        width="full"
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </form>
                        </Box>
                    </Stack>
                </Flex>
            </Container>
        </Layout>
    );
};

export default LoginFeature;
